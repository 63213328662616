import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { AudditPlanningType } from "./auditPlanningTypes";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { usePermissions } from "common/Permissions";
import { DateTime } from "luxon";
import { formatStatus } from "utils/formatStatus";
import { AlignmentType, Paragraph, TextRun } from "docx";
import { relatedItemsParse, useGetGroupByRelatedItem } from "utils/useGetGroupByRelatedItems";

export const auditPlanningReportSchema = (): ReportConfig<AudditPlanningType> => {
  const getFilename = useGenerateReportFilename();
  const { groups } = usePermissions();
  return{
    getFilename: (item) =>
      getFilename({
        type: "filename",
        fileName: `Decipher Risk - Audit ${item.title ?? "title"}`,
        timestamp: DateTime.now(),
      }),
    fields:[
      { type: "Header", content: (item) => item.title ?? "" },
      {
        type: "InlineText",
        label: "Status",
        content: (item) => formatStatus(item?.status),
      },
      {
        type: "InlineText",
        label: "Audit Period",
        content: (item) => item.days ? `${item.days} (days)` : "",
      },
      {
        type: "RichText",
        label: "Background",
        content: (item) => item.background ?? "",
      },
      {
        type: "RichText",
        label: "Objective of Audit",
        content: (item) => item.objective ?? "",
      },
      {
        type: "RichText",
        label: "Scope",
        content: (item) => item.scope ?? "",
      },
      {
        type: "RichText",
        label: "Methodology",
        content: (item) => item.methodology ?? "",
      },
      {
        type: "RichText",
        label: "Audit Findings",
        content: (item) => item.auditFindings ?? "",
      },
      {
        type: "RichText",
        label: "Recommendations",
        content: (item) => item.recommendations ?? "",
      },
      {
        type: "RichText",
        label: "Management Response",
        content: (item) => item.managementResponse ?? "",
      },
      {
        type: "InlineText",
        label: "Audit Start Date",
        content: (item) => item?.auditStartDate,
      },
      {
        type: "InlineText",
        label: "Audit end Date",
        content: (item) => item?.auditEndDate,
      },
      {
        type: "InlineText",
        label: "Audit was conducted by",
        content: (item) => item?.auditor,
      },
      {
        type: "InlineText",
        label: "Total Hours",
        content: (item) => item?.hours,
      },
      {
        type: "InlineText",
        label: "Audit was conducted by",
        content: (item) => item?.auditor,
      },
      {
        type: "Custom",
        label: "Related Items",
        formatting: (item) => [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            heading: "Heading2",
            children: [
              new TextRun({ text: "Related Items", bold: true, break: 1 }),
            ],
          }),
          ...relatedItemsParse(
            useGetGroupByRelatedItem(item?.auditPlanningRelatedItems?.items, groups),
          ),
        ],
      },
      {
        type:'InlineText',
        label: 'Follow up Actions',
        content: () => '',
      },
    ],
  }
}