import React, { useMemo, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { HGForm, HGToggleSubmit } from "common/HGForm";
import { useMutation, ApolloError } from "@apollo/client";
import { modifyAuditPlanning } from "graphql/mutations";
import gql from "graphql-tag";
import * as yup from "yup";
import AuditPlanningInputs from "./AuditPlanningInputs";
import { useParams } from "react-router";
import {
  useGetAuditPlan,
  useGetAuditPlansInGroup,
} from "utils/connectors/auditPlanningConnectors";
import {
  ModifyAuditPlanningMutationVariables,
  ModifyAuditPlanningMutation,
  UpdateAuditPlanningInput,
} from "API";
import { AuditStatus } from "utils/formatStatus";
import { DateTime } from "luxon";
import Loader from "common/Loader";
import { RelatedArtifacts } from "common/RelatedItems";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { usePermissions } from "common/Permissions";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";

export const EditAuditPlanning: React.FC<{
  onComplete?: Function;
}> = () => {
  const { id } = useParams<{ id: string; tab: string }>();
  const { group } = usePermissions();
  const { auditPlan, refetch } = useGetAuditPlan(id);
  const rightSidebarCollapsed = localStorage.getItem("rightsidebar-collapsed");
  const [isExpanded, setIsExpanded] = useState(!rightSidebarCollapsed);
  const { auditPlans, auditPlansTemplates } = useGetAuditPlansInGroup();
  const modifyActionPlanSchema = useMemo(() => {
    return yup.object<Partial<UpdateAuditPlanningInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          `Audit plan${
            auditPlan?.isTemplate === true ? "template" : ""
          } title already exists!`,
          (value) => {
            return !isUniqueTitle(
              (!auditPlan.isTemplate === true
                ? auditPlans
                : auditPlansTemplates
              ).filter((i) => i.id !== auditPlan.id),
              value
            );
          }
        ),
      riskDomain: yup
        .string()
        .default(auditPlan?.riskDomain ?? undefined)
        .label("Risk Domain"),
      year: yup
        .string()
        .default("")
        .label("Budget Year")
        .test(
          "is-date",
          // eslint-disable-next-line
          "${path} should be in YYYY format",
          (value) => {
            if (value) {
              const date = DateTime.fromFormat(value, "yyyy");
              return date?.isValid;
            }
            return true;
          }
        ),
      hours: yup
        .string()
        .nullable()
        .notRequired()
        .default(auditPlan?.hours)
        .label("Budgeted Hours")
        .matches(/^\d*\.?\d*$/, "Hours must be a positive number or zero")
        .test(
          "is-positive-or-zero",
          "Budgeted Hours must be a positive number or zero",
          (value) =>
            value === null ||
            value === "" ||
            (value !== undefined && parseFloat(value) >= 0)
        ),
      owner: yup
        .string()
        .default(auditPlan?.owner ?? undefined)
        .required()
        .label("Owner"),
      days: yup
        .string()
        .nullable()
        .notRequired()
        .default(auditPlan?.days)
        .label("Audit Period(days)")
        .matches(
          /^\d*\.?\d*$/,
          "Audit Period(days) must be a positive number or zero"
        )
        .test(
          "is-positive-or-zero",
          "Audit Period(days) must be a positive number or zero",
          (value) =>
            value === null ||
            value === "" ||
            (value !== undefined && parseFloat(value) >= 0)
        ),
      status: yup
        .mixed()
        .oneOf(Object.values(AuditStatus))
        .required()
        .default(
          auditPlan?.status === "Complete"
            ? "Closed"
            : auditPlan?.status ?? AuditStatus.Planning
        )
        .label("Status"),
      preliminaryAuditWork: yup
        .string()
        .default("")
        .label("Preliminary Audit Work"),
      background: yup.string().default("").label("Background"),
      objective: yup.string().label("Objeactive Of Audit"),
      scope: yup.string().default("").label("Scope"),
      methodology: yup.string().default("").label("Methodology"),
      points: yup
        .string()
        .default("")
        .label(
          "Detailed Audit Points(What will be reviewed in records or account)"
        ),
      summary: yup.string().default("").label("Summary"),
      auditFindings: yup.string().default("").label("Audit Findings"),
      recommendations: yup.string().default("").label("Recommendations"),
      managementResponse: yup.string().default("").label("Management Response"),
      auditStartDate: yup
        .string()
        .default("")
        .label("Audit Start Date")
        .test(
          "is-date",
          // eslint-disable-next-line
          "${path} should be in MM/DD/YYYY format",
          (value) => {
            if (value) {
              const date = DateTime.fromFormat(value, "MM/dd/yyyy");
              return date?.isValid;
            }
            return true;
          }
        ),
      auditEndDate: yup
        .string()
        .default("")
        .label("Audit End Date")
        .test(
          "is-date",
          // eslint-disable-next-line
          "${path} should be in MM/DD/YYYY format",
          (value) => {
            if (value) {
              const date = DateTime.fromFormat(value, "MM/dd/yyyy");
              return date?.isValid;
            }
            return true;
          }
        ),
      auditor: yup.string().default("").label("Audit was conducted by"),
      totalHours: yup
        .string()
        .nullable()
        .notRequired()
        .default(auditPlan?.totalHours)
        .label("Total Hours")
        .matches(/^\d*\.?\d*$/, "Total Hours must be a positive number or zero")
        .test(
          "is-positive-or-zero",
          "Total Hours must be a positive number or zero",
          (value) =>
            value === null ||
            value === "" ||
            (value !== undefined && parseFloat(value) >= 0)
        ),
    });
  }, [auditPlan, auditPlans]);

  const [mutationModifyAuditPlan] = useMutation<
    ModifyAuditPlanningMutation,
    ModifyAuditPlanningMutationVariables
  >(gql(modifyAuditPlanning), {
    onCompleted: () => {
      ToastifyQueue("Audit Plan Updated Successfully.", "success");
      refetch();
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
    // errorPolicy: "all",
  });

  return (
    (auditPlan === undefined && <Loader />) || (
      <HGForm
        key="form_editAuditPlanningDetails"
        onSubmit={async (input) => {
          return mutationModifyAuditPlan({
            variables: {
              input: {
                ...input,
                id,
              },
            },
          });
        }}
        editToggle
        resolver={modifyActionPlanSchema}
      >
        <Row className="px-2">
          <Col xs={isExpanded ? 12 : 8}>
            <Card>
              <Card.Header>
                <HGToggleSubmit className="float-right" />
                Details
              </Card.Header>
              <Card.Body>
                <Row>
                  <AuditPlanningInputs
                    auditPlan={auditPlan}
                    groupID={group.id}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <div className="aside">
            <RelatedArtifacts
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              refetchItem={refetch}
            />
          </div>
        </Row>
      </HGForm>
    )
  );
};
