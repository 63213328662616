import { HGRichText } from "common/HGForm/HGRichText";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useGetRiskDomainInGroup } from "utils/connectors/riskDomainConnectors";
import { useUserEnum } from "utils/dataFormatters";
import { AuditStatus, formatStatus } from "utils/formatStatus";
import { useFormContext } from "react-hook-form";
import { HGDatePicker } from "common/HGForm/HGDatePicker";
import "./AuditPlanning.scss";
import { HGSelect } from "common/HGForm/Inputs";
import { usePermissions } from "common/Permissions";
import {
  HGNumber,
  HGInputBox,
  HGCheckBox,
  HGCheckBoxGroup,
} from "common/HGForm/Inputs";

const defaultPreliminaryAuditWork = {
  "49aa3396-cba6-11ec-9d64-0242ac120001": "false",
  "49aa3396-cba6-11ec-9d64-0242ac120002": "false",
  "49aa3396-cba6-11ec-9d64-0242ac120003": "false",
  "49aa3396-cba6-11ec-9d64-0242ac120004": "false",
  "49aa3396-cba6-11ec-9d64-0242ac120005": "false",
};

const defaultSummary = {
  "49aa3396-cba6-11ec-9d64-0242ac120006": "false",
  "49aa3396-cba6-11ec-9d64-0242ac120007": "false",
  "49aa3396-cba6-11ec-9d64-0242ac120008": "false",
  "49aa3396-cba6-11ec-9d64-0242ac120009": "false",
};

const resolveIdToStringFn = (id) => {
  switch (id) {
    case "49aa3396-cba6-11ec-9d64-0242ac120001":
      return "Conduct pre-audit planning.";
    case "49aa3396-cba6-11ec-9d64-0242ac120002":
      return "Review previous audit's work papers,planning points & reports.";
    case "49aa3396-cba6-11ec-9d64-0242ac120003":
      return "Research guidelines, OIG reports, CMS MLN articles, etc.";
    case "49aa3396-cba6-11ec-9d64-0242ac120004":
      return "Retrieve data as necessary.";
    case "49aa3396-cba6-11ec-9d64-0242ac120005":
      return "Gather data necessary to conduct audit/process review (policies, procedures, etc).";
    case "49aa3396-cba6-11ec-9d64-0242ac120006":
      return "Write up draft summary.";
    case "49aa3396-cba6-11ec-9d64-0242ac120007":
      return "Review draft summary with mannager or director.";
    case "49aa3396-cba6-11ec-9d64-0242ac120008":
      return "Finalize summary.";
    case "49aa3396-cba6-11ec-9d64-0242ac120009":
      return "Prepare executive summary for Corporate Compliance Committee.";
    default:
      break;
  }
};

const AuditPlanningInputs: React.FC<{
  auditPlan?: any;
  groupID?: any;
  isTemplate?: any;
  setIsTemplate?: any;
}> = ({ auditPlan, groupID, isTemplate, setIsTemplate }) => {
  const methods = useFormContext();
  const { getOrganizationUser } = usePermissions();
  const { register, setValue, getValues } = methods;
  const _riskDomain = useGetRiskDomainInGroup();
  const riskDomains = [{ id: null, title: "" }];
  _riskDomain.riskDomains.forEach(function (element) {
    return element.archived === true
      ? riskDomains.push({
          id: element?.id,
          title: `${element?.title} (Archived)`,
        })
      : riskDomains.push({ id: element?.id, title: element?.title });
  });
  const users = useUserEnum(groupID);

  const [_preAuditWork, _setPreAuditWork] = useState(
    defaultPreliminaryAuditWork
  );

  const [_summary, _setSummary] = useState(defaultSummary);

  const loadedPreAuditWork = auditPlan?.preliminaryAuditWork;
  const loadedSummary = auditPlan?.summary;

  useEffect(() => {
    if (!loadedPreAuditWork) return;
    _setPreAuditWork({
      ...defaultPreliminaryAuditWork,
      ...JSON.parse(loadedPreAuditWork),
    });
  }, [loadedPreAuditWork]);

  useEffect(() => {
    setValue("preliminaryAuditWork", JSON.stringify(_preAuditWork));
    setValue("summary", JSON.stringify(_summary));
  }, [_preAuditWork, _summary]);

  useEffect(() => {
    if (!loadedSummary) return;
    _setSummary({
      ...defaultSummary,
      ...JSON.parse(loadedSummary),
    });
  }, [loadedSummary]);
  const formValues = getValues();
  return (
    <>
      <Col sm={12}>
        <HGInputBox
          name="title"
          label="Title"
          defaultValue={auditPlan?.title}
        />
      </Col>
      {isTemplate !== undefined && (
        <Col sm={12}>
          <HGCheckBox
            id="isTemplate"
            label="Create as Template"
            checkGroup="isTemplate"
            checked={isTemplate}
            onChange={() => setIsTemplate((prevState) => !prevState)}
          />
        </Col>
      )}
      <Col sm={6}>
        <HGSelect
          name="riskDomain"
          label="Risk Domain"
          options={riskDomains || []}
          defaultValue={auditPlan?.riskDomain}
          overrideFormat={auditPlan?.riskDomainDetails?.title || ""}
        />
      </Col>
      <Col sm={6}>
        <HGNumber
          label="Budget Year"
          name="year"
          defaultValue={auditPlan?.year}
        />
      </Col>
      <Col sm={6}>
        <HGNumber
          label="Budgeted Hours"
          name="hours"
          defaultValue={auditPlan?.hours}
        />
      </Col>
      <Col sm={6}>
        <HGSelect
          name="owner"
          label="Owner"
          options={users}
          defaultValue={auditPlan?.owner}
          overrideFormat={
            getOrganizationUser(auditPlan?.owner)?.displayName || ""
          }
        />
      </Col>
      <Col sm={6}>
        <HGNumber
          label="Audit Period (days)"
          name="days"
          defaultValue={auditPlan?.days}
        />
      </Col>
      <Col sm={6}>
        <HGSelect
          label="Status"
          name="status"
          defaultValue={auditPlan?.status}
          enumObj={AuditStatus}
          overrideFormat={formatStatus(auditPlan?.status)}
        />
      </Col>
      <Col sm={12}>
        <HGRichText
          label="Background"
          name="background"
          defaultValue={auditPlan?.background}
        />
      </Col>
      <Col sm={12}>
        <HGRichText
          label="Objective Of Audit"
          name="objective"
          defaultValue={auditPlan?.objective}
        />
      </Col>
      <Col sm={12}>
        <HGRichText
          label="Scope"
          name="scope"
          defaultValue={auditPlan?.scope}
        />
      </Col>
      <Col sm={12}>
        <HGRichText
          label="Methodology"
          name="methodology"
          defaultValue={auditPlan?.methodology}
        />
      </Col>
      <Col sm={12}>
        <HGCheckBoxGroup
          label="Preliminary Audit Work"
          name="preliminaryAuditWork"
          enumObj={_preAuditWork}
          resolveIdToStringFn={resolveIdToStringFn}
          setEnumObj={_setPreAuditWork}
          idPrefix={"customPreAuditCheckbox"}
        />
      </Col>
      <Col sm={12} className="mt-3">
        <HGRichText
          label="Detailed Audit Points (What will be reviewed in records or account)"
          name="points"
          defaultValue={auditPlan?.points}
        />
      </Col>
      <Col sm={12}>
        <HGCheckBoxGroup
          name="summary"
          label="Summary"
          enumObj={_summary}
          resolveIdToStringFn={resolveIdToStringFn}
          setEnumObj={_setSummary}
          idPrefix={"customSummaryCheckbox"}
        />
      </Col>
      <Col sm={12} className="mt-3">
        <HGRichText
          label="Audit Findings"
          name="auditFindings"
          defaultValue={auditPlan?.auditFindings}
        />
      </Col>
      <Col sm={12}>
        <HGRichText
          label="Recommendations"
          name="recommendations"
          defaultValue={auditPlan?.recommendations}
        />
      </Col>
      <Col sm={12}>
        <HGRichText
          label="Management Response"
          name="managementResponse"
          defaultValue={auditPlan?.managementResponse}
        />
      </Col>
      <Col sm={3}>
        <HGDatePicker
          name="auditStartDate"
          label="Audit Start Date"
          defaultValue={auditPlan?.auditStartDate || ""}
          max={formValues.auditEndDate}
        />
      </Col>
      <Col sm={3}>
        <HGDatePicker
          name="auditEndDate"
          label="Audit End Date"
          defaultValue={auditPlan?.auditEndDate || ""}
          min={formValues?.auditStartDate}
        />
      </Col>
      <Col sm={6}>
        <HGInputBox
          name="auditor"
          label="Audit was conducted by"
          defaultValue={auditPlan?.auditor}
        />
      </Col>
      <Col sm={6}>
        <HGNumber
          name="totalHours"
          label="Total Hours"
          defaultValue={auditPlan?.totalHours}
        />
      </Col>
    </>
  );
};
export default AuditPlanningInputs;
