import React from "react";
import { GetAuditPlanningQuery } from "API";
import { HGRichTextRead } from "common/HGForm/HGRichText";
import { ITableRecordFormatting } from "common/TabbedTable";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getUserFormat,
  getDateFormat,
  getDateDisplayFormat,
  getDateTimeFormat,
  getDateTimeDisplayFormat,
} from "utils/dataFormatters";
import { StatusBadge, RelatedItemBadge } from "common/Badges";
import { AuditStatus, formatObj, formatStatus } from "utils/formatStatus";
import { useGetRiskDomainInGroup } from "utils/connectors/riskDomainConnectors";
import * as R from "ramda";
import { usePermissions } from "common/Permissions";
import { getRelatedActionPlansSummary, getRelatedIssueSummary, getRelatedItemsSearchString } from "formatRelatedItemsSearchString";

type schemaParams = {
  archiveAuditPlan?: Function;
  userRole?: string;
  showGroupPrefix?: boolean;
};

export const useAuditPlanningSchema = (params?: schemaParams) => {
  const _riskDomains = useGetRiskDomainInGroup();
  const auditPlanStatus = formatObj(AuditStatus, formatStatus);
  
  const formatter: Array<
  ITableRecordFormatting<
  NonNullable<GetAuditPlanningQuery["getAuditPlanning"]>
  >
  > = [
    {
      id: "title",
      name: "Title",
      format: (auditPlan) => auditPlan.title,
      tableDisplayFormat: (auditPlan) => {
        return <Link to={`/audits/${auditPlan.id}`}>{auditPlan.title}</Link>;
      },
      required: true,
      first: true,
    },
    {
      id: "actionPlans",
      name: "Related Action Plans",
      format: (auditPlanning) =>
        getRelatedItemsSearchString(auditPlanning.auditPlanningRelatedItems?.items,getRelatedActionPlansSummary),
      tableDisplayFormat: (auditPlanning) => {
        const items = auditPlanning.auditPlanningRelatedItems?.items;
        return (
          <RelatedItemBadge
            items={items}
            display="issue"
            filter="actionplans"
          />
        );
      },
      required: true,
      last: true,
    },
    {
      id: "issues",
      name: "Related Issues",
      format: (issue) =>
        getRelatedItemsSearchString(issue.auditPlanningRelatedItems?.items, getRelatedIssueSummary),
      tableDisplayFormat: (actionPlan) => {
        const items = actionPlan.auditPlanningRelatedItems?.items;
        return (
          <RelatedItemBadge items={items} display="issue" filter="issues" />
        );
      },
      required: true,
      last: true,
    },
    {
      id: "riskDomain",
      name: "Risk Domain",
      format: (auditPlan) => {
        return _riskDomains.riskDomains?.find(
          (s) => auditPlan.riskDomain === s.id,
        )?.archived
          ? `${
            _riskDomains.riskDomains?.find(
              (s) => auditPlan.riskDomain === s.id,
            )?.title
          }  (Archived)`
          : _riskDomains.riskDomains?.find((s) => auditPlan.riskDomain === s.id)
            ?.title;
      },
    },
    {
      id: "year",
      name: "Budget Year",
      format: (auditPlan) => auditPlan.year,
      defaultVisible: true,
    },
    {
      id: "hours",
      name: "Budgeted Hours",
      format: (auditPlan) => auditPlan.hours,
      defaultVisible: true,
    },
    {
      id: "owner",
      name: "Owner",
      format: getUserFormat("auditPlanningOwner"),
      defaultVisible: false,
    },
    {
      id: "days",
      name: "Audit Period (days)",
      format: (auditPlan) => auditPlan.days,
      defaultVisible: false,
    },
    {
      id: "status",
      name: "Status",
      format: ({ status }) => formatStatus(status),
      tableDisplayFormat: ({ status }) => <StatusBadge status={status} />,
      defaultVisible: true,
      type: "Options",
      optionValues: auditPlanStatus,
    },
    {
      id: "background",
      name: "Background",
      format: (auditPlan) => auditPlan?.background,
      tableDisplayFormat: (auditPlan) => (
        <HGRichTextRead content={auditPlan?.background} />
      ),
      defaultVisible: false,
    },
    {
      id: "objective",
      name: "Objective Of Audit",
      format: (auditPlan) => auditPlan?.objective,
      tableDisplayFormat: (auditPlan) => (
        <HGRichTextRead content={auditPlan?.objective} />
      ),
      defaultVisible: false,
    },
    {
      id: "scope",
      name: "Scope",
      format: (auditPlan) => auditPlan?.scope,
      tableDisplayFormat: (auditPlan) => (
        <HGRichTextRead content={auditPlan?.scope} />
      ),
      defaultVisible: false,
    },
    {
      id: "methodology",
      name: "Methodology",
      format: (auditPlan) => auditPlan?.methodology,
      tableDisplayFormat: (auditPlan) => (
        <HGRichTextRead content={auditPlan?.methodology} />
      ),
      defaultVisible: false,
    },
    {
      id: "points",
      name: "Detailed Audit Points",
      format: (auditPlan) => auditPlan?.points,
      tableDisplayFormat: (auditPlan) => (
        <HGRichTextRead content={auditPlan?.points} />
      ),
      defaultVisible: false,
    },
    {
      id: "auditFindings",
      name: "Audit Findings",
      format: (auditPlan) => auditPlan?.auditFindings,
      tableDisplayFormat: (auditPlan) => (
        <HGRichTextRead content={auditPlan?.auditFindings} />
      ),
      defaultVisible: false,
    },
    {
      id: "recommendations",
      name: "Recommendations",
      format: (auditPlan) => auditPlan?.recommendations,
      tableDisplayFormat: (auditPlan) => (
        <HGRichTextRead content={auditPlan?.recommendations} />
      ),
      defaultVisible: false,
    },
    {
      id: "managementResponse",
      name: "Management Response",
      format: (auditPlan) => auditPlan?.managementResponse,
      tableDisplayFormat: (auditPlan) => (
        <HGRichTextRead content={auditPlan?.managementResponse} />
      ),
      defaultVisible: false,
    },
    {
      id: "auditStartDate",
      name: "Audit Start Date",
      type: "Date",
      format: getDateFormat("auditStartDate"),
      displayFormat: getDateDisplayFormat("auditStartDate"),
      defaultVisible: false,
    },
    {
      id: "auditEndDate",
      name: "Audit End Date",
      type: "Date",
      format: getDateFormat("auditEndDate"),
      displayFormat: getDateDisplayFormat("auditEndDate"),
      defaultVisible: false,
    },
    {
      id: "auditor",
      name: "Audit was conducted by",
      format: (auditPlan) => auditPlan?.auditor,
      defaultVisible: false,
    },
    {
      id: "totalHours",
      name: "Total Hours",
      format: (auditPlan) => auditPlan?.totalHours,
      defaultVisible: false,
    },
    {
      id: "createdBy",
      name: "Created By",
      format: getUserFormat("auditPlanningCreatedBy"),
      defaultVisible: false,
    },
    {
      id: "modifiedBy",
      name: "Modified By",
      format: getUserFormat("auditPlanningModifiedBy"),
      defaultVisible: false,
    },
    {
      id: "createdAt",
      name: "Created At",
      type: "Date",
      format: getDateTimeFormat("createdAt"),
      displayFormat: getDateTimeDisplayFormat("createdAt"),
      defaultVisible: false,
    },
    {
      id: "lastModified",
      name: "Last Modified",
      type: "Date",
      format: getDateTimeFormat("lastModified"),
      displayFormat: getDateTimeDisplayFormat("lastModified"),
      defaultVisible: false,
    },
    {
      name: "",
      format: () => "",
      tableDisplayFormat: (auditPlan) => (
        params?.userRole !== 'read'
          ? <Button
          variant="outline-primary"
          onClick={() =>
            params?.archiveAuditPlan?.(auditPlan.id, !auditPlan.archived)
          }
          >
          {(auditPlan.archived && "Unarchive") || "Archive"}
            </Button>
          :           <></>
      ),
      required: true,
      last: true,
    },
  ];
  return formatter;
};
