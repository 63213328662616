import {
  CreateNestedReportProps,
  NestedReportConfig,
  ReportConfig,
  ReportConfigFieldEntry,
} from "./reportSchemaParse.types";
import { Paragraph, TextRun, Table, AlignmentType } from "docx";
import { traverseDOM, getOlCount } from "./docUtils";

export type ReportSchemaParseOptions = {
  ols: number;
};

export type ReportSchemaParseReturn = Array<Paragraph | Table>;

export const reportGetFilename = <T>(schema: ReportConfig<T>, item: T) =>
  schema.getFilename?.(item) ?? "DecipherRisk Export.docx";

export const reportSchemaParse = async <T>(
  schema: ReportConfig<T>,
  item: T,
  options?: ReportSchemaParseOptions
): Promise<ReportSchemaParseReturn> => {
  const _ols = 0;
  return await Promise.all(schema.fields.map(schemaMapper(item))).then(
    (structure) => structure.flat(2)
  );
};

export type CreateNestedReportFunctionType = <T, U>(
  options: CreateNestedReportProps<T, U>
) => (parent?: T) => NestedReportConfig<U>;

export const createNestedReport: CreateNestedReportFunctionType =
  <T, U>(options) =>
  (parent?: T) => {
    return {
      forceItemExists: options.forceItemExists,
      pageBreak: options.pageBreak,
      item: options.itemResolver(parent),
      fields: options.fields,
    };
  };

export type AsyncCreateNestedReportFunctionType = <T, U>(
  options: CreateNestedReportProps<T, U>
) => (parent?: T) => Promise<NestedReportConfig<U>>;
export const asyncCreateNestedReport: AsyncCreateNestedReportFunctionType =
  <T, U>(options) =>
  async (parent?: T) => {
    return {
      forceItemExists: options.forceItemExists,
      pageBreak: options.pageBreak,
      item: await options.itemResolver(parent),
      fields: options.fields,
    };
  };
export const schemaMapper =
  <T>(item) =>
  async (schemaEntry: ReportConfigFieldEntry<T>) => {
    switch (schemaEntry.type) {
      case "Header":
        return [
          new Paragraph({
            children: [new TextRun({ text: schemaEntry.content(item) })],
            heading: "Heading1",
          }),
        ];
      case "Title":
        return [
          new Paragraph({
            children: [
              new TextRun({
                text: "Title: ",
                bold: true,
              }),
              new TextRun({
                text: schemaEntry.content(item),
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
        ];
      case "InlineText":
        return [
          new Paragraph({
            children: [
              new TextRun({ text: `${schemaEntry.label}: `, bold: true }),
              new TextRun({ text: schemaEntry.content(item) }),
            ],
          }),
        ];
      case "RichText":
        if (schemaEntry.hideIf?.(item)) {
          return [];
        }
        var parser = new DOMParser();
        var doc1 = parser.parseFromString(
          schemaEntry.content(item),
          "text/html"
        );

        var [result] = traverseDOM(doc1.body, {}, true);

        return [
          new Paragraph({
            children: [
              new TextRun({ text: schemaEntry.label, bold: true, break: 1 }),
            ],
          }),
          ...result.map((element) =>
            element?.properties?.rootKey === "w:rPr"
              ? new Paragraph({ children: [element] })
              : element
          ),
        ];
      case "Custom":
      case "RelatedItems":
        return schemaEntry.formatting(item);
      case "CustomAsync":
        return schemaEntry.formatting(item);
      case "NestedReport":
        let nestedSchema = schemaEntry.config?.(item);
        if (nestedSchema.forceItemExists && !nestedSchema.item) {
          return [];
        }
        const nestedReport = await Promise.all(
          nestedSchema.fields.map(schemaMapper(nestedSchema.item))
        ).then((structure) => structure.flat(2));
        if (nestedSchema.pageBreak) {
          return [new Paragraph({ pageBreakBefore: true }), ...nestedReport];
        }
        return nestedReport;
      case "AsyncNestedReport":
        let asyncNestedSchema = await schemaEntry.config?.(item);
        if (asyncNestedSchema.forceItemExists && !asyncNestedSchema.item) {
          return [];
        }
        const asyncNestedReport = await Promise.all(
          asyncNestedSchema.fields.map(schemaMapper(asyncNestedSchema.item))
        ).then((structure) => structure.flat(2));
        if (asyncNestedSchema.pageBreak) {
          return [
            new Paragraph({ pageBreakBefore: true }),
            ...asyncNestedReport,
          ];
        }
        return asyncNestedReport;
      default:
        return [];
    }
  };
