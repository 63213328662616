import React from "react";
export const getActionPlanTaskCompletionStats = (tasks) => {
  if (tasks?.length === 0 || !tasks) {
    return -1;
  }
  const closedTasks = tasks.filter(
    (taskItem) => taskItem.task.status === "Closed"
  );
  const percentage = (closedTasks.length / tasks.length) * 100;
  if (closedTasks.length === 0) {
    return 0; // Return 0% if there are no closed tasks
  }
  return percentage;
};
