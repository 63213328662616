import { ApolloError, useMutation } from "@apollo/client";
import {
  CreateAuditPlanningInput,
  CreateAuditPlanningMutation,
  CreateAuditPlanningMutationVariables,
} from "API";
import { HGForm, HGFormSubmit } from "common/HGForm";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { usePermissions } from "common/Permissions";
import { GroupSelector } from "common/layout/Header/GroupSelector";
import gql from "graphql-tag";
import { createAuditPlanning } from "graphql/mutations";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGetAuditPlansInSelectedGroup } from "utils/connectors/auditPlanningConnectors";
import { AuditStatus } from "utils/formatStatus";
import { cancelPopupMessage } from "utils/globalVariables";
import * as yup from "yup";
import AuditPlanningInputs from "./AuditPlanningInputs";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";

export const CreateAuditPlanning: React.FC<{
  onComplete?: Function;
}> = ({ onComplete }) => {
  const history = useHistory();
  const { group, userId } = usePermissions();
  const getItemInSelectedGroup = useGetAuditPlansInSelectedGroup();
  const [groupID, setGroupID] = useState(group.id);
  const [auditPlans, setAuditPlans] = useState([]);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);

  const [makeAuditPlan] = useMutation<
    CreateAuditPlanningMutation,
    CreateAuditPlanningMutationVariables
  >(gql(createAuditPlanning), {
    onCompleted: (response) => {
      ToastifyQueue("Audit Plan Created Successfully.", "success");
      history.push("/audits");
      onComplete?.(response?.CreateAuditPlanning);
      setGroupID(group.id);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
    // errorPolicy: "all",
  });

  const createAuditPlanningSchema = useMemo(() => {
    return yup.object<Partial<CreateAuditPlanningInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          "Audit Plan title already exists!",
          (value) => {
            if (!value) return true;
            return !isUniqueTitle(auditPlans, value);
          }
        ),
      riskDomain: yup.string().default("").label("Risk Domain"),
      year: yup
        .string()
        .default("")
        .label("Budget Year")
        .test(
          "is-date",
          // eslint-disable-next-line
          "${path} should be in YYYY format",
          (value) => {
            if (value) {
              const date = DateTime.fromFormat(value, "yyyy");
              return date?.isValid;
            }
            return true;
          }
        ),
      hours: yup
        .string()
        .nullable()
        .notRequired()
        .default(null)
        .label("Budgeted Hours")
        .matches(/^\d*\.?\d*$/, "Hours must be a positive number or zero")
        .test(
          "is-positive-or-zero",
          "Budgeted Hours must be a positive number or zero",
          (value) =>
            value === null ||
            value === "" ||
            (value !== undefined && parseFloat(value) >= 0)
        ),
      owner: yup.string().default(userId).required().label("Owner"),
      days: yup
        .string()
        .nullable()
        .notRequired()
        .default(null)
        .label("Audit Period(days)")
        .matches(
          /^\d*\.?\d*$/,
          "Audit Period(days) must be a positive number or zero"
        )
        .test(
          "is-positive-or-zero",
          "Audit Period(days) must be a positive number or zero",
          (value) =>
            value === null ||
            value === "" ||
            (value !== undefined && parseFloat(value) >= 0)
        ),
      status: yup
        .mixed()
        .oneOf(Object.values(AuditStatus))
        .required()
        .default(AuditStatus.Pending)
        .label("Status"),
      background: yup.string().default("").required().label("Background"),
      objective: yup
        .string()
        .default("")
        .required()
        .label("Objeactive Of Audit"),
      scope: yup.string().default("").required().label("Scope"),
      methodology: yup.string().default("").label("Methodology"),
      preliminaryAuditWork: yup
        .string()
        .default("")
        .label("Preliminary Audit Work"),
      points: yup
        .string()
        .default("")
        .label(
          "Detailed Audit Points(What will be reviewed in records or account)"
        ),
      summary: yup.string().default("").label("Summary"),
      auditFindings: yup.string().default("").label("Audit Findings"),
      recommendations: yup.string().default("").label("Recommendations"),
      managementResponse: yup.string().default("").label("Management Response"),
      auditStartDate: yup
        .string()
        .default("")
        .label("Audit Start Date")
        .test(
          "is-date",
          // eslint-disable-next-line
          "${path} should be in MM/DD/YYYY format",
          (value) => {
            if (value) {
              const date = DateTime.fromFormat(value, "MM/dd/yyyy");
              return date?.isValid;
            }
            return true;
          }
        ),
      auditEndDate: yup
        .string()
        .default("")
        .label("Audit End Date")
        .test(
          "is-date",
          // eslint-disable-next-line
          "${path} should be in MM/DD/YYYY format",
          (value) => {
            if (value) {
              const date = DateTime.fromFormat(value, "MM/dd/yyyy");
              return date?.isValid;
            }
            return true;
          }
        ),
      auditor: yup.string().default("").label("Audit was conducted by"),
      totalHours: yup
        .string()
        .nullable()
        .notRequired()
        .default(null)
        .label("Total Hours")
        .matches(/^\d*\.?\d*$/, "Total Hours must be a positive number or zero")
        .test(
          "is-positive-or-zero",
          "Total Hours must be a positive number or zero",
          (value) =>
            value === null ||
            value === "" ||
            (value !== undefined && parseFloat(value) >= 0)
        ),
      archived: yup.boolean().required().default(false),
      groupID: yup.string().default(groupID).required().label("GroupID"),
      isTemplate: yup.boolean(),
    });
  }, [userId, auditPlans, groupID]);

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      history.push("/audits");
    }
  }

  useEffect(() => {
    getItemInSelectedGroup({
      variables: { id: groupID },
    }).then((auditPlans) => {
      setAuditPlans(auditPlans);
    });
  }, [groupID]);
  return (
    <HGForm
      onSubmit={async (input) => {
        return makeAuditPlan({
          variables: { input: { ...input, isTemplate } },
        });
      }}
      resolver={createAuditPlanningSchema}
    >
      <Row className="px-2">
        <Col xs="12">
          <Card>
            <Card.Header>
              <Row>
                <Col sm="9">Create Audit Plan</Col>
                <Col sm="3">
                  <div className="float-right">
                    <GroupSelector
                      groupID={groupID}
                      onChange={(id) => {
                        setGroupID(id);
                      }}
                      isAttachment
                    />
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <AuditPlanningInputs
                  groupID={groupID}
                  isTemplate={isTemplate}
                  setIsTemplate={setIsTemplate}
                />
              </Row>
            </Card.Body>
            <Card.Footer className="text-right w-100">
              <Button
                variant="light"
                className="mr-2 cancel-btn"
                onClick={() => {
                  Confirmation();
                  setGroupID(group.id);
                }}
              >
                Cancel
              </Button>
              <HGFormSubmit />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </HGForm>
  );
};
