import React from "react";
import { EditAuditPlanning } from "Modules/Customers/Audit/AuditPlanning/EditAuditPlanning";
import { Breadcrumb, Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useParams } from "react-router";
import { AuditPlanningTemplatebutton } from "../AuditPlanningTemplate";
import { useGetAuditPlan } from "utils/connectors/auditPlanningConnectors";
import DownloadItem from "common/DownloadItem";
import AuditPlanningRelatedItems from "./AuditPlanningRelatedItems";
import { useGetArtifactsInGroup } from "utils/connectors/artifactsConnectors";
import { AttributeDateDisplay } from "common/FormExtras";
import { usePermissions } from "common/Permissions";
import { StickyHeader } from "common/StickyHeader";
import GenerateReport from "utils/docx-export/GenerateReport";
import { auditPlanningReportSchema } from "../auditPlanningReportSchema";

export const AuditPlanningPage = () => {
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const history = useHistory();
  const { auditPlan, refetch } = useGetAuditPlan(id);
  const { groupRole } = usePermissions();
  return (
    <>
      <StickyHeader>
        <Col xs="12" className="pr-0">
          <Breadcrumb>
            <LinkContainer to="/audits" exact>
              <Breadcrumb.Item>Audit</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active={false}>{auditPlan?.title}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Row>
          <Col xs="6" className="pr-0">
            <h3>Edit Audit Plan</h3>
            <Nav variant="tabs" style={{ display: "inline-flex" }}>
              <LinkContainer exact to={`/audits/${id}/details`}>
                <Nav.Link>Details</Nav.Link>
              </LinkContainer>
              <LinkContainer to={`/audits/${id}/relatedItems`}>
                <Nav.Link>Related Items</Nav.Link>
              </LinkContainer>
            </Nav>
          </Col>
          <Col xs="6">
            <div style={{ marginLeft: "200px" }}>
              <AttributeDateDisplay item={auditPlan} />
            </div>
          </Col>
        </Row>
      </StickyHeader>
      {tab === "details" && (
        <Row className="mt-205">
          <Col sm="12" className="float-right">
            {tab === "details" && (
              <span className="d-block text-right">
                {groupRole !== "read" && (
                  <AuditPlanningTemplatebutton
                    auditPlan={auditPlan}
                    onComplete={(response) => {
                      const auditPlanTemplate =
                        response?.CloneAuditPlan || response?.AuditPlanTemplate;
                      const auditPlanTemplateId =
                        (auditPlanTemplate?.[0] &&
                          JSON.parse(auditPlanTemplate?.[0])?.id) ??
                        null;
                      if (auditPlanTemplateId) {
                        history.push(`/audits/${auditPlanTemplateId}`);
                      } else {
                        refetch();
                      }
                    }}
                  />
                )}
                <GenerateReport
                  type="Single"
                  item={auditPlan}
                  schema={auditPlanningReportSchema()}
                />
                {/* <DownloadItem item={downloadAuditPlanObj} itemType='Audit Summary'/> */}
              </span>
            )}
          </Col>
          <EditAuditPlanning />
        </Row>
      )}
      {tab === "relatedItems" && <AuditPlanningRelatedItems />}
    </>
  );
};
